import React from "react"

import Seo from "../components/seo"
import Banner from "../components/banner"
import Intro from "../components/intro"
import Middle from "../components/middle"
import WhatWeDo from "../components/what-we-do"
import Contact from "../components/contact"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Banner />
    <Intro />
    <Middle />
    <WhatWeDo />
    <Contact />
  </>
)

export default IndexPage
