import React from "react"
import Image from "../components/image"
import * as styles from "./sections.module.css"
import { Row, Col } from "react-bootstrap"

const Middle = () => (
  <Row className={styles.sectionBorder + ` mt-5 pb-3`}>
    <Col sm={{ span: 6, order: 12 }} className="image fit main">
      <Image src="stock/imac-coder-min.jpg" />
    </Col>
    <Col sm={{ span: 6, order: 1 }}>
      <header className="major">
        <h2>If it hurts, do it more often</h2>
        <p>Bring the pain forward</p>
      </header>
      <p>
        We'll guide your team through the creating a release process that's
        right for you. We know time to market is critical and we know what works
        and what doesn't. From large scale multi-brand corporates to small start
        ups, we've got you covered. 
      </p>
    </Col>
  </Row>
)

export default Middle
