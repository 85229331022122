import React from "react"
import Image from "../components/image"
import { Row, Col } from "react-bootstrap"
import * as styles from "./sections.module.css"

export default function Banner() {
  return (
    <Row className={styles.sectionBorder + ` pt-5 pb-3`}>
      <Col
        xs={{ span: 12, order: 2 }}
        sm="8"
        className={styles.bannerStyles + " text-center p-5"}
      >
        <h2 className={styles.bannerHeading}>
          There's no such thing as "dev done"
        </h2>
        <p className={styles.bannerTagline}>
          Software team struggling to deliver? We can help!
        </p>
      </Col>
      <Col xs={{ span: 12, order: 1 }} sm="4">
        <Image src="branding/logo2.png" />
      </Col>
    </Row>
  )
}
