import React from "react"
import * as styles from "./sections.module.css"
import { Row, Col } from "react-bootstrap"

export default function WhatWeDo() {
  return (
    <Row className={styles.sectionBorder + ` mt-5 pb-3`}>
      <Col xs="12" sm="12" md="12">
        <header className="major text-center">
          <h2>Services we offer</h2>
          <p>Tailored Software consultancy.</p>
        </header>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-flask"></span>
        <h3>Organisational Transformation</h3>
        <p>Bringing about culture change to drive innovation</p>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-comment"></span>
        <h3>Coaching for Engineering Teams</h3>
        <p>
          We can advise, consult and train your developers to work smarter, not
          harder!
        </p>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-sticky-note-o"></span>
        <h3>Design Sprints</h3>
        <p>
          Sharpies and Post It notes at the ready! We want to help bring your
          product to life!
        </p>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-forward"></span>
        <h3>Continuous Delivery and DevOps</h3>
        <p>Nothing drives business vlaue like increased release frequency</p>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-cloud"></span>
        <h3>Cloud Architecture</h3>
        <p>Microservices? Serverless? Cloud Native? We can help!</p>
      </Col>

      <Col xs="12" sm="6" md="4" className="text-center">
        <span className="icon alt major fa-child"></span>
        <h3>People over Processes</h3>
        <p>We will help you be more than Agile!</p>
      </Col>
    </Row>
  )
}
