import React from "react"
import Image from "../components/image"
import { Row, Col } from "react-bootstrap"
import * as styles from "./sections.module.css"

const Intro = () => (
  <Row className={styles.sectionBorder + ` mt-5 pb-3`}>
    <Col sm="6" className="image fit main">
      <Image src="stock/code-min.jpg" />
    </Col>

    <Col>
      <header className="major">
        <h2>Today Every Company is a Software Company</h2>
        <p>
          With over 15 years experience delivering high quality software
          products.
        </p>
      </header>
      <p>
        Dev Done is an independent consultancy with skills from across the
        software industry. We specialise in helping software teams deliver on
        time and under budget. We know the value of shipping early and shipping
        often when it comes to software.
      </p>

      <p>
        We specialise in Continuous Delivery, Microservices, DevOps, Cloud
        Architecture and Lean Agile methodologies.
      </p>
      <p>
        We're here to help you go from one release per month or year to multiple
        per day! From monolith to microservices. Whatever your needs, we can
        help.
      </p>
    </Col>
  </Row>
)

export default Intro
